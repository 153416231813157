import Plugin from '@ckeditor/ckeditor5-core/src/plugin'
import ButtonView from '@ckeditor/ckeditor5-ui/src/button/buttonview'
import imageIcon from '@ckeditor/ckeditor5-core/theme/icons/image.svg'

export default class CKEditorPlugin extends Plugin {
  init () {
    const editor = this.editor
    editor.model.schema.register('component', {
      inheritAllFrom: '$text',
      allowAttributes: 'test',
      isInline: true
    })
    editor.conversion.elementToElement({ model: 'component', view: 'component' })

    // Allow <div> elements in the model to have all attributes.
    editor.model.schema.addAttributeCheck(context => {
      if (context.endsWith('component')) {
        return true
      }
    })

    // View-to-model converter converting a view <div> with all its attributes to the model.
    editor.conversion.for('upcast').elementToElement({
      view: 'component',
      model: (viewElement, modelWriter) => {
        return modelWriter.createElement('component', viewElement.getAttributes())
      }
    })

    // Model-to-view converter for the <div> element (attrbiutes are converted separately).
    editor.conversion.for('downcast').elementToElement({
      model: 'component',
      view: 'component'
    })

    // Model-to-view converter for <div> attributes.
    // Note that a lower-level, event-based API is used here.
    editor.conversion.for('downcast').add(dispatcher => {
      dispatcher.on('attribute', (evt, data, conversionApi) => {
        // Convert <div> attributes only.
        if (data.item.name != 'component') {
          return
        }

        const viewWriter = conversionApi.writer
        const viewDiv = conversionApi.mapper.toViewElement(data.item)

        // In the model-to-view conversion we convert changes.
        // An attribute can be added or removed or changed.
        // The below code handles all 3 cases.
        if (data.attributeNewValue) {
          viewWriter.setAttribute(data.attributeKey, data.attributeNewValue, viewDiv)
        } else {
          viewWriter.removeAttribute(data.attributeKey, viewDiv)
        }
      })
    })

    editor.ui.componentFactory.add('insertImage', locale => {
      const view = new ButtonView(locale)

      view.set({
        label: 'Insert image',
        icon: imageIcon,
        tooltip: true
      })
      // Callback executed once the image is clicked.
      view.on('execute', () => {
        editor.model.change(writer => {
          const imageElement = writer.createElement('component', { is: 'el-button' })
          console.log(writer.createElement, imageElement)
          // Insert the image in the current selection location.
          editor.model.insertContent(imageElement, editor.model.document.selection)
        })
      })

      return view
    })
  }
}
